<template>
  <b-overlay
    id="overlay-background"
    :show="loading"
    variant="transparent"
    :opacity="0.9"
    blur="true"
    rounded="sm"
  >
    <b-card>
      <!-- media -->
      <b-media no-body>
        <b-media-aside>
          <b-link>
            <b-img
              ref="previewEl"
              rounded
              :src="avatar"
              height="200"
            />
          </b-link>
          <!--/ avatar -->
        </b-media-aside>

        <b-media-body class="mt-75 ml-75">
          <!-- upload button -->
          <!-- :disabled="$can('Update', 'Settings') === false" -->
          <b-button
            disabled
            variant="primary"
            class="mb-75 mr-75"
            @click="$refs.refInputEl.$el.click()"
          >
            {{ $t('Upload') }}
          </b-button>
          <b-form-file
            ref="refInputEl"
            v-model="profileFile"
            accept=".jpg, .png, .gif"
            :hidden="true"
            plain
            @input="inputImageRenderer"
          />
          <!--/ upload button -->

          <b-card-text class="tw-text-text-primary">
            {{ $t('Allowed JPG, GIF or PNG. Max size of 800kB.') }}
          </b-card-text>
        </b-media-body>
      </b-media>
      <!--/ media -->

      <!-- form -->
      <b-form class="mt-2">
        <b-row>
          <b-col
            sm="12"
            lg="6"
          >
            <b-form-group
              :label="$t('Company Name')"
              label-for="account-username"
            >
              <b-form-input
                v-model="companyName"
                :disabled="!hasSystemSettingsActions"
                :placeholder="$t('Company Name')"
              />
            </b-form-group>
          </b-col>
          <b-col
            sm="12"
            lg="6"
          >
            <b-form-group
              :label="$t('Language')"
              label-for="account-username"
            >
              <ProzessSelect
                v-model="language"
                :options="languageOptions"
              />
            </b-form-group>
          </b-col>

          <b-col
            v-show="false"
            cols="12"
            class="mt-75"
          >
            <b-alert
              show
              variant="warning"
              class="mb-50"
            >
              <h4 class="alert-heading">Your email is not confirmed. Please check your inbox.</h4>
            </b-alert>
          </b-col>

          <b-col
            cols="12"
            class="d-flex justify-content-end"
          >
            <b-button
              :disabled="!hasSystemSettingsActions || sending"
              variant="primary"
              class="mt-2 mr-1 d-flex"
              @click="updateCompany"
            >
              <b-spinner
                v-if="sending"
                class="mr-1"
                small
              />
              {{ $t('Save changes') }}
            </b-button>
            <b-button
              variant="outline-secondary"
              type="reset"
              class="mt-2"
              @click.prevent="resetForm"
            >
              {{ $t('Reset') }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BFormFile,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BAlert,
  BCard,
  BCardText,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BSpinner,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from 'vue'
import { mapState } from 'vuex'
import { languageOptions } from '@/constants/app'
import logoPlaceholder from '../../../assets/logo-placeholder.jpg'
import coreService from '../../../services/core'
import ProzessSelect from '@/@core/components/ProzessSelect.vue'

export default {
  components: {
    BSpinner,
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BOverlay,
    ProzessSelect,
  },
  directives: {
    Ripple,
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl)

    return {
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
  data() {
    return {
      avatar: logoPlaceholder,
      companyName: '',
      language: '',
      profileFile: null,
      sending: false,
      languageOptions,
    }
  },
  computed: {
    ...mapState('user', ['user']),
    loading() {
      return this.user == null
    },
  },
  watch: {
    user: {
      handler(val) {
        this.companyName = val.companyName
        this.language = val.language ? this.languageOptions.find(lang => lang.key === val.language) : this.languageOptions[0]
        console.log('languageOptions', this.languageOptions)
      },
      immediate: true,
    },
  },
  methods: {
    updateCompany() {
      this.sending = true
      const payload = {
        company_name: this.companyName,
        language: this.language.key,
      }
      coreService
        .updateCompany(payload)
        .then(() => {
          this.$getUserAndPermissions()
        })
        .finally(() => {
          this.sending = false
        })
    },
    resetForm() {
      this.companyName = this.user.companyName
    },
  },
}
</script>
